<template>
    <modal v-model="model" :title="title">
        <template #body>
            <template v-if="loading">
                <section class="box options">
                    <spin-loader :showMe="loading" />
                </section>
            </template>
            <template v-else>
                <section v-if="showCardCreate" class="box options margin-bottom-xsmall">
                    <section>
                        <div class="input-group nowrap">
                            <form v-on:submit.prevent="null">
                                <input type="text" v-model="newCardName" class="style-alternate fullwidth" placeholder="New Card name..." autocomplete="off">
                            </form>
                        </div>
                        <div>
                            <checkbox-list :inpopup="true" v-model="cardDisplayTypes" idField="id" labelField="label" selectField="selected" class="list-horizontal distribute-evenly margin-top-min" :showAsBox="true" :multiple="multiCardCreate"></checkbox-list>
                        </div>
                        <p v-if="multiCardCreate" class="ui-hint">Select multiple types to create multiple cards</p>
                    </section>
                    <fieldset v-if="false" v-show="$refs.newCardTypes && multiCardCreate && $refs.newCardTypes.selectedChoice.length > 1" class="checkbox margin-top-xsmall">
                        <input v-model="nameByType" name="nameByType" :id="'saveCard-nameByType'" type="checkbox">
                        <label :for="'saveCard-nameByType'">Append Type to the Card's Name</label>
                    </fieldset>
                    <fieldset v-show="action.toLowerCase() == 'save' && showSnapshotCheck && false" class="checkbox margin-top-small">
                        <input v-model="useLiveData" name="useLiveData" :id="'saveCard-searchSnapshot'" type="checkbox">
                        <label :for="'saveCard-searchSnapshot'">
                            Use Live Data
                            <span class="ui-hint help" title="Checking this option will link the search to the card. Unchecking this option will put the search results inside a list, and then link the list to the card."></span>
                        </label>
                    </fieldset>
                    <section v-show="!useLiveData" class="margin-top-xsmall">
                        <span>A new List will be created to hold the current Search Results.</span>
                        <div class="margin-top-small">
                            <label style="margin-bottom:0px">List Name</label>
                            <input style="margin-top:0px" type="text" v-model="newListName" class="style-alternate fullwidth" :placeholder=newCardName autocomplete="off">
                        </div>
                        <fieldset class="checkbox margin-top-small">
                            <input v-model="deepSave" name="deepSave" :id="'saveCard-deepSave'" type="checkbox">
                            <label :for="'saveCard-deepSave'">
                                Find Primary Contacts
                                <span class="ui-hint help" title="Checking this option will add one Primary Contact for every Company saved."></span>
                            </label>
                        </fieldset>
                    </section>
                </section>

                <section v-else-if="showSnapshotCheck" class="box options margin-bottom-xsmall">
                    <section class="">
                        <fieldset class="checkbox">
                            <input v-model="deepSave" name="deepSave" :id="'saveCard-deepSave'" type="checkbox">
                            <label :for="'saveCard-deepSave'">
                                Find Primary Contacts
                                <span class="ui-hint help" title="Checking this option will add one Primary Contact for every Company saved."></span>
                            </label>
                        </fieldset>
                    </section>
                </section>
                <section class="fullwidth">
                    <label style="text-align:center;" v-if="showCardSelect">Choose a Dashboard and Card. {{ cardFilter ? 'Only cards with a '+cardFilter+' filter will be shown' : ''}}</label>
                    <label style="text-align:center;" v-else>Choose a Dashboard to save your {{ selectedCardTypes.length > 1 ? selectedCardTypes.length : '' }} Card{{ selectedCardTypes.length > 1 ? 's' : '' }} in</label>
                    <section class="box options">
                        <section class="box-section no-border" v-if="showDashCreate">
                            <ul class="menu-flex fullwidth">
                                <li class="menu-item">
                                    <a class="menu-option newDashGroup" href="javascript:;">
                                        <i class="fas fa-columns"></i>
                                        <input v-model="newDashboardName" class="style-blank" type="text" placeholder="New Dashboard name..." autocomplete="off">
                                        <button v-show="!loading" class="button-primary small" @click="selectDashboard(null, newDashboardName)">{{ actionText.toLowerCase() != 'create' ? 'Create &amp; ' + actionText + ' ' + cardText : actionText }}</button>
                                        <spin-loader :showMe="loading" />
                                    </a>
                                </li>
                            </ul>
                        </section>
                        <section class="box-section no-border margin-top-small" v-if="showDashCreate">
                            <div class="type-note align-center">Or choose from existing Dashboards:</div>
                        </section>
                        <section>
                            <div class="flex-rows">
                                <dropdown class="place-right" ref="sortDropdown" type="icon" icon="fas fa-sort-alt" :useFontAwesome="true" direction="right" :title="'Sort: ' + sortByLabel(sortOptions, sortBy) + (sortBy.asc ? ' (asc)' : ' (desc)')">
                                    <template #header>
                                        <h3>Sort by: </h3>
                                    </template>
                                    <template #body>
                                        <sort-menu @sortselected="$refs.sortDropdown.closeTheDropDown()" :options="sortOptions" v-model="sortBy"></sort-menu>
                                    </template>
                                </dropdown>
                            </div>
                        </section>
                        <section class="box section no-border">
                            <ul class="menu-flex fullwidth dashboardItemList">
                                <template v-if="shownDashboards.length">
                                    <li v-for="(dash, idx) in shownDashboards" :key="idx" class="menu-item">
                                        <a :class="(isDisabled(dash.id) ? 'is-disabled' : '') + ' menu-option ' + (dash.open ? ' is-selected hard-select' : '')" @mouseenter="isActive" @mouseleave="isInactive" @click="openDashboard(dash.id)" href="javascript:;">
                                            <i class="fas fa-columns"></i>
                                            <span class="option-label">{{ dash.name }}</span>
                                            <span class="option-count">{{ dash.open ? '' : ((dash.cards ? dash.cards.length : 0) +  ' Card' + (dash.cards && dash.cards.length > 1 ? 's' : '')) }}</span>
                                            <template v-if="!isDisabled(dash.id)">
                                                <button v-if="!showCardSelect" class="option-apply button-primary small" @click="selectDashboard(dash.id, '', dash.dateRangeKey)">{{actionText}} {{cardText}}</button>
                                            </template>
                                            <template v-else>
                                                <button class="option-apply button-primary small">Cannot {{ actionText }} {{cardText}} to this Dashboard</button>
                                            </template>

                                            <ul v-if="showCardSelect && dash.open" class="list-icons list-vertical">
                                                <li v-for="(card, idx) in dash.cards" :key="dash.id+idx">
                                                    <i class="far fa-tachometer-alt-fast"></i>
                                                    <span class="option-label">{{shortCardName(card.name)}}</span>&nbsp;
                                                    <button class="option-apply button-primary small place-right" @click="selectCard(card.ownerID, card.id, card.filterID)">{{actionText}} {{cardText}}</button>
                                                </li>
                                            </ul>
                                        </a>
                                    </li>
                                    <li v-show="dashboards.length > shownDashboards.length">
                                        <button class="option-apply button-primary small" @click="showMoreDashboards()">Show more</button>
                                    </li>
                                </template>
                                <template v-else>
                                    <li v-if="showCardSelect && cardFilter" class="menu-item">No Valid {{ cardFilter }} Cards found</li>
                                    <li v-else-if="showCardSelect" class="menu-item">No Cards found</li>
                                    <li v-else class="menu-item">No Dashboards found</li>
                                </template>
                            </ul>
                        </section>
                    </section>
                </section>
            </template>
        </template>
    </modal>
</template>

<script>
    import { ref, computed } from 'vue'
    import appMaps from '../../mixins/appMaps.js'
    import { getSearchTools } from '../../composables/SearchTools.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { mapGetters } from 'vuex'
    import _ from 'lodash'

    import SpinLoader from '../baseComponents/SpinLoader.vue'
    import SortMenu from '../Menus/SortMenu.vue'
    import sortHelper from '../../mixins/sortHelper.js'
    import CheckboxList from '../ui/forms/CheckboxList.vue'

    export default {
        name: 'DashboardSelect',
        components: {
            SortMenu,
            SpinLoader,
            CheckboxList
        },
        mixins: [appMaps, sortHelper],
        emits: ['dash-selected', 'card-selected', 'update:modelValue'],
        props: {
            modelValue: { type: Boolean, default: false },
            card: { type: Object, default: null },
            cardDateRangeKeys: { type: Array, default: new Array() },
            obj: { type: Object, default: null },
            title: { type: String, default: "Select Dashboard" },
            action: { type: String, default: "Select" },
            actionLabel: { type: String, default: "" },
            actionToLabel: { type: String, default: "to" },
            disabledDashboardIDs: { type: Array, default: new Array() },
            showDashCreate: { type: Boolean, default: true },
            newDashName: { type: String, default: "" },
            showCardCreate: { type: Boolean, default: false },
            multiCardCreate: { type: Boolean, default: true },
            initialNewCardName: { type: String, default: "" },
            initialNewCardType: { type: String, default: "" },
            showSnapshotCheck: { type: Boolean, default: false },
            showCardSelect: { type: Boolean, default: false },
            cardFilter: { type: String, default: '' },
            openNewCardInNewTabOverride: { type: Boolean, default: false },
        },
        setup() {
            const { searchMaps } = getSearchTools()
            const cardTypesHelperMap = ref({
                "1": { order: 5 }, //stats
                "2": { order: 2 }, //table
                "3": { order: 6 }, //brands
                "4": { order: 1 }, //details
                "5": { order: 7 }, //graph
                "6": { order: 4 }, //tag cloud
                "7": { order: 8 }, //map
                "9": { order: 3 }, //grid
            });

            const { dashboardNameCollision } = getTextTools()
            const rawCardDisplayTypes = searchMaps.value && searchMaps.value.cardDisplayTypes ? searchMaps.value.cardDisplayTypes : [];
            const unsortedCardDisplayTypes = _.filter(_.map(rawCardDisplayTypes, function (type, idx) {
                return {
                    id: idx,
                    label: type == 'Geographic Map' ? 'Map' : type,
                    selected: false,
                    order: cardTypesHelperMap.value[idx] ? cardTypesHelperMap.value[idx].order : 99
                }
            }), function (type) { return type.label != 'Unknown' });

            const cardDisplayTypes = ref(_.sortBy(unsortedCardDisplayTypes, 'order'));
            const selectedCardTypes = computed(() => _.filter(cardDisplayTypes.value, function (cardType) { return cardType.selected }));

            return {
                searchMaps, cardTypesHelperMap,
                cardDisplayTypes, selectedCardTypes, dashboardNameCollision
            }
        },
        data: function () {
            return {
                dashboards: [],
                loading: false,
                newCardName: _.cloneDeep(this.initialNewCardName),
                newListName: "",
                newDashboardName: "",
                nameByType: true,
                useLiveData: true,
                deepSave: false,
                showAllDashboards: false,
                maxShown: 5,
                sortOptions: [
                    { label: 'Index', key: 'idx', defaultAscending: true },
                    { label: 'Name', key: 'name', type: 'String', defaultAscending: true },
                    { label: 'Change Date', key: 'changeDate', type: 'Date' },
                    { label: 'Entry Date', key: 'entryDate', type: 'Date' },
                    { label: 'Number of Cards', key: 'cards', type: 'Length',  }],
                sortBy: { key: 'changeDate', asc: false },
                isOpen: false
            }
        },
        computed: {
            ...mapGetters({
                dashboardGroup: 'userInfo/dashboardGroup'
            }),
            model: {
                get() {
                    return this.modelValue || this.isOpen
                },
                set(value) {
                    this.isOpen = value
                    this.$emit('update:modelValue', value)
                }
            },
            actionText: function () {
                return this.actionLabel ? this.actionLabel : this.action;
            },
            actionToText: function () {
                return this.actionText + ' ' + this.actionToLabel;
            },
            shownDashboards: function () {
                if (this.showAllDashboards)
                    return this.sortedDashboards;
                else
                    return this.sortedDashboards.slice(0, this.maxShown);
            },
            sortedDashboards: function () {
                return this.sortItems(this.dashboards, this.sortBy);
            },
            cardText() {
                return this.action.toLowerCase() != 'pin' ? 'Card' : ''
            }
        },
        methods: {
            resetState: function () {
                var me = this;
                me.initDashboards();
                me.showAllDashboards = false;
                me.newCardName = _.cloneDeep(me.initialNewCardName);
                me.newDashboardName = ""
                me.nameByType = true;
                me.useLiveData = true;
                me.deepSave = false;
                me.sortBy = { key: 'changeDate', asc: false };

                _.each(me.cardDisplayTypes, function (cardType) {
                    cardType.selected = me.initialNewCardType == cardType.id || me.initialNewCardType == cardType.label ? true : false
                })

                if (me.cardDisplayTypes && me.selectedCardTypes.length == 0)
                    me.cardDisplayTypes[0].selected = true;

            },
            isDisabled: function (id) {
                return this.disabledDashboardIDs.includes(id) || (this.action.toLowerCase() == 'move' && (this.card && this.card.ownerID == id));
            },
            isActive: function (ev) {
                ev.target.classList.add("is-selected");
            },
            isInactive: function (ev) {
                if (!ev.target.classList.contains('hard-select'))
                    ev.target.classList.remove("is-selected");
            },
            showTheModal: function () {
                this.model = true
            },
            closeTheModal: function () {
                this.model = false
            },
            shortCardName: function (name) {
                if (name.length > 20)
                    return name.substring(0, 20) + '...';
                return name;
            },
            openDashboard: function (id) {
                if (!this.isDisabled(id)) {
                    var dash = _.find(this.dashboards, function (dash) { return dash.id == id });
                    if (dash) {
                        var newValue = !dash.open
                        if (newValue)
                            _.each(this.dashboards, function (dash) { dash.open = false; });

                        dash.open = newValue;
                    }
                }
            },
            selectCard: function (ownerID, id, filterID) {

                //emit card selection
                var card = { id: id, name: '', ownerID: ownerID, filterID: filterID, deepSave: this.deepSave };
                this.$emit('card-selected', card);


                //emit dash selection
                var dCard = _.cloneDeep(card)
                delete dCard.ownerID;
                delete dCard.deepSave;

                var dash = { id: ownerID, name: '', cards: [dCard] };
                this.$emit('dash-selected', dash);
                this.model = false
            },
            selectDashboard: function (id, name, dateRangeKey) {
                var me = this;
                if (!id && !name) {
                    me.$toast.add({ severity: 'error', summary: 'The new Dashboard needs a name', life: 3000 })
                    return;
                }
                else if (me.dashboardNameCollision(name, me.shownDashboards)) {
                    me.$toast.add({ severity: 'error', summary: "The name is already in use by another Dashboard", life: 3000 });
                    return;
                }


                var dash = {
                    id: id ? id : null,
                    name: name ? name : '',
                    cards: [],
                    dateRangeKey: dateRangeKey ? dateRangeKey : 'AllTime'
                };

                //keep the user data for card creation if it's there
                if (me.showCardCreate) {
                    //need name 
                    if (!me.newCardName) {
                        this.$toast.add({ severity: 'error', summary: 'The new Card needs a name', life: 3000 })
                        return;
                    }

                    //need type
                    if (!this.selectedCardTypes.length) {
                        this.$toast.add({ severity: 'error', summary: 'The new Card needs a type', life: 3000 })
                        return;
                    }

                    //add type to the name if creating multiple cards with nameByType checked
                    var addNameByType = this.nameByType && this.selectedCardTypes.length > 1;

                    //put the cards in the dashboard
                    _.each(this.selectedCardTypes, function (type) {
                        dash.cards.push({
                            id: null,
                            name: addNameByType ? me.newCardName + ' {cardDisplayType}' : me.newCardName,
                            cardDisplayType: type.id,
                        })
                    });
                }

                switch (this.action.toLowerCase()) {
                    case 'move':
                        if (this.card && this.card.id)
                            this.moveToDashboard(dash);
                        else {
                            this.$emit('dash-selected', dash);
                            this.model = false
                        }
                        break;
                    case 'copy':
                    case 'pin':
                    case 'save':
                        if (this.obj && this.obj.id)
                            this.saveToDashboard(dash);
                        else {
                            this.$emit('dash-selected', dash);
                            this.model = false
                        }
                        break;
                    default:
                        this.$emit('dash-selected', dash);
                        this.model = false
                }
            },
            saveToDashboard: function (dash) {
                if (dash && this.obj && this.obj.id) {
                    var opt = {
                        ownerID: dash.id,
                        ownerName: dash.name,
                        sourceID: this.obj.id,
                        sourceObjectType: this.obj.objectType,
                        name: this.obj.name,
                        query: this.obj.objectType == 'query' ? this.obj : null,
                        lockDateFilter: this.cardDateRangeKeys.length ? !this.cardDateRangeKeys.includes(dash.dateRangeKey) : false
                    };


                    if (!this.useLiveData && opt.query) {
                        //get the query
                        this.loading = true;
                        var listName = this.newListName ? this.newListName : this.newCardName;
                        var subsetName = "Search - " + opt.query.description;
                        this.$store.dispatch('search/createSearchSnapshot', { listName: listName, subsetName: subsetName, query: opt.query, deepSave: this.deepSave, createQuery: true }).then((result) => {
                            this.loading = false;
                            if (result) {
                                var newQuery = JSON.parse(result.jsonResult);
                                this.$toast.add({ severity: 'success', summary: 'Created Snapshot', detail: 'New Snapshot created. Creating Cards...', life: 3000 })
                                opt.query = newQuery;
                                this.createCards(opt, dash);
                            }
                        }).catch(error => {
                            this.loading = false;
                            this.$toast.add({ severity: 'error', summary: 'Error Creating Snapshot', detail: error, life: 5000 })
                        });
                    }
                    else 
                        this.createCards(opt, dash);
                }
            },
            createCards: function (opt, dash) {
                //if there are cards then prioritize their data
                var optArray = [];
                if (dash.cards.length) {
                    _.each(dash.cards, function (card) {
                        var cardOpt = _.cloneDeep(opt);

                        if (card.name)
                            cardOpt.name = card.name;
                        if (card.cardDisplayType)
                            cardOpt.cardDisplayType = card.cardDisplayType;

                        optArray.push(cardOpt);
                    });
                }
                else
                    optArray.push(opt);

                console.log(optArray);
                this.loading = true;
                this.$store.dispatch('dashboards/createCards', optArray).then((result) => {
                    this.loading = false;
                    this.model = false
                    this.$toast.add({ severity: 'success', summary: 'Created Card', detail: 'New Card ' + (!dash.id && dash.name ? 'and Dashboard' : '') + ' created', life: 3000 })

                    //open separate window if we create the card in a different dashboard than the current one
                    if (result && result.ownerID) {
                        if (this.openNewCardInNewTabOverride || (this.card && this.card.ownerID != result.ownerID) || !this.card)
                            window.open("/dashboard/" + result.ownerID, "_blank");
                    }

                }).catch(error => {
                    this.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Error Creating Card', detail: error, life: 5000 })
                });

            },
            moveToDashboard: function (dash) {
                if (this.card && this.card.id) {
                    this.loading = true;
                    this.$store.dispatch('dashboards/moveCard', {
                        'id': this.card.id,
                        'ownerID': this.card.ownerID,
                        'newOwnerID': dash.id,
                        'newOwnerName': dash.id ? '' : dash.name
                    }).then(() => {
                        this.loading = false;
                        this.$toast.add({ severity: 'success', summary: 'Moved Card', detail: 'Moved to another dashboard', life: 3000 })

                    }).catch(error => {
                        this.loading = false;
                        this.$toast.add({ severity: 'error', summary: 'Error Moving Card', detail: error, life: 5000 })
                    });
                }
            },
            showMoreDashboards: function () {
                this.showAllDashboards = true;
            },
            initDashboards: function () {
                var me = this;
                me.dashboards = [];
                if (me.dashboardGroup) {
                    var dashboards = _.cloneDeep(me.dashboardGroup.dashboards);
                    _.each(dashboards, function (dash) {
                        if (me.cardFilter == 'list') {
                            dash.cards = _.filter(dash.cards, function (card) {
                                if (card.query && card.query.criteria) {
                                    if (card.query.criteria["lists"] && card.query.criteria["lists"].value) {
                                        var mspList = JSON.parse(card.query.criteria["lists"].value);
                                        if (mspList.listSets && mspList.listSets.length == 1 &&
                                            mspList.listSets[0] &&
                                            (mspList.listSets[0].subsetIDs == null || mspList.listSets[0].subsetIDs.length == 0) &&
                                            (mspList.listSets[0].binIDs == null || mspList.listSets[0].binIDs.length == 0)) {
                                            card.filterID = mspList.listSets[0].id;
                                            return true;
                                        }
                                    }
                                    if (card.query.criteria["listID"] && card.query.criteria["listID"].value) {
                                        card.filterID = card.query.criteria["listID"].value;
                                        return true;
                                    }
                                }
                                return false;
                            });
                        }

                        //add the valid dashboards
                        if (!me.showCardSelect || dash.cards.length) {
                            dash.open = false;
                            me.dashboards.push(dash);
                        }
                    });
                }
            },
        },
        watch: {
            "model": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal)
                        this.resetState()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>