<template>
    <template v-if="model">
        <section class="box record margin-top-small">
            <section class="box-content-viewer">
                <record v-model="model"
                        v-model:editing="isEditing"
                        v-model:dirty="isDirty"
                        v-model:selTab="selectedTab"
                        :cardDisplayType="4"
                        :editorID="editorID"
                        recordContext="detailspage"
                        @editordone="editorDone($event)"
                        @editrequest="openEditMode($event)"
                        :ref="(el)=>setItemRef(el,'modelRecord')" />
            </section>
            <footer class="box-footer record-footer margin-top-small">
                <record-menu v-model:editing="isEditing"
                             :editorID="editorID"
                             @button-clicked="handleRecordMenuClicked"
                             :obj="model" />
            </footer>
        </section>
    </template>
    <template v-else-if="fetchFailed">
        <h3>{{ objectType }}::{{ id }} not found</h3>
    </template>
    <template v-else>
        <div class="margin-top margin-left">
            Loading {{ objectTypeText }}... <spin-loader :showMe="true" />
        </div>
    </template>
</template>

    <script>
        import { ref, computed, inject, onBeforeUnmount, onMounted, provide, onBeforeUpdate } from 'vue'
        import { useStore } from 'vuex'
        import { getID } from '../../composables/Editors.js'
        import { useToast, useItemRefs } from '../../composables/ModelWrapper.js'

        import Record from '../Records/Record.vue'
        import RecordMenu from "../Records/RecordMenu.vue"

        export default {
            name: 'DetailsPage',
            props: {
                'id': { type: Number },
                'objectType': { type: String, default: 'company' },
                'editMode': { type: Boolean, default: false },
                'commentID': { type: Number, default: 0 },
                'personID': { type: Number, default: 0 }
            },
            components: {
               Record, RecordMenu
            },
            setup(props) {
                // tools
                const store = useStore()
                const emitter = inject("emitter")   // Inject event bus 
                const toast = useToast()
                const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()
                onBeforeUpdate(() => {
                    itemRefsReset()
                })

                // data
                const editorID = ref(null) 
                const fetching = ref(false)
                const fetchFailed = ref(false)
                const editorLoadedOnce = ref(false)
                const isEditing = ref({})
                const isDirty = ref({})
                const selectedTab = ref('')

                // computed
                const user = computed(() => store.state.userInfo.user)
                const objectCache = computed(() => store.getters['objectCache/cache'])
                const emailTemplates = computed(() => store.getters['letterwizard/emailTemplates'])
                const objectTypeText = computed(() => props.objectType ? props.objectType.charAt(0).toUpperCase() + props.objectType.slice(1) : 'Unknown')
                const objectKey = computed(() => props.id && props.objectType ? props.objectType + '::' + props.id : null)
                const storeKey = computed(() => objectKey.value ? objectKey.value.replace('::', '__'): null)
                const model = computed(() => objectCache.value && objectCache.value[storeKey.value] && objectCache.value[storeKey.value].obj ? objectCache.value[storeKey.value].obj : null)
                const validModel = computed(() => model.value && model.value.id && model.value.objectType)
                const name = computed(() => model.value ? model.value.name : props.objectType + ' loading')

                   // methods
                const openEditMode = (commentID,personID) => {
                    emitter.emit('editCommentRecordBody', { editorID: editorID.value, obj: { id: commentID ? commentID : 0, objectType: 'comment', personID: personID ? personID : 0 } })
                }
                const fetchModel = () => {
                    if (objectKey.value) {
                        fetching.value = true
                        store.dispatch('objectCache/fetchObject', { 'objectKey': objectKey.value, fullFetch: true }).then((results) => {
                            if (results) {
                                console.log('Fetched Model', results)
                            }
                            if ((props.commentID || props.editMode) && !editorLoadedOnce.value)
                                openEditMode(props.commentID,props.personID)

                            fetching.value = false
                        }).catch(error => {
                            if (error.fatal) {
                                fetchFailed.value = true;
                                toast.add({ severity: 'error', summary: 'Error loading ' + props.objectType, detail: error.message, life: 3000 })
                            }
                            fetching.value = false
                        })
                    }
                }
                const titleClick = () => {
                    if (props.objectType && props.id) {
                        var url = '/' + props.objectType + '/details/' + props.id
                        window.open(url, '_blank')
                    }
                }
                const handleRecordMenuClicked = (ev) => {
                    //console.log('handleRecordMenuClicked', ev, model.value)
                    var refModelRecord = itemRefs.value && itemRefs.value.modelRecord ? itemRefs.value.modelRecord : null
                    if (ev.action === 'save' && refModelRecord) {
                        refModelRecord.saveEdits()
                    }
                    else if (ev.action === 'cancel') {
                        editorDone('cancel');
                    }
                    else if (ev.action == 'switchMode' && refModelRecord) {
                        isEditing.value.comment = true;
                        refModelRecord.toggleView()
                    }
                    else if (ev.action === 'openEditMode') {
                        isEditing.value.comment = { id: null, objectType: 'comment' };
                    }
                    else if (ev.action === 'pinToDashboard') {
                        toast.add({ severity: 'error', summary: 'Pin to dashboard ' + ev.id + ' not implemented', life: 3000 })
                    }
                    else {
                        toast.add({ severity: 'error', summary: ev.action + ' not implemented', life: 3000 })
                    }
                }
                const editorDone = (status) => {
                    isEditing.value = {}
                    console.log('Comment Editor Done: ' + status)
                }
                const asteriskHandler = (req, value) => {
                    console.log('* listener', req, 'val', value)
                }
                const doFilterRequest = (opt) => {
                    //console.log('applyFilter-detailspage', opt)
                    var url = '/search/vue?' + encodeURIComponent(opt.filter) + '=' + encodeURIComponent(opt.filterVal) + '&docType=' + encodeURIComponent(opt.docType ?? 'Company')
                    window.open(url, '_blank')
                }

                provide('query', null)
                provide('highlightText', null)
               /* provide('handleFilterRequest', (opt) => {
                    window.open('/search/vue?' + encodeURIComponent(opt.filter) + '=' + encodeURIComponent(opt.filterVal) + '&docType=' + this.objectType, '_blank')
                })*/


                // lifecycle
                onMounted(() => {
                    editorID.value = getID('details_page')
                    fetching.value = false
                    fetchFailed.value = false
                    editorLoadedOnce.value = false
                    isEditing.value = {}
                    isDirty.value = {}

                    emitter.on("objectUpdate", asteriskHandler)
                    emitter.on("applyFilter", doFilterRequest)
                    store.dispatch('objectCache/viewObject', { 'objectKey': objectKey.value }).then(() => {
                        console.log(objectKey.value + ' Viewed');
                    }).catch(error => {
                        console.log(error)
                    })

                    if (!model.value) fetchModel()

                })

                onBeforeUnmount(() => {
                    emitter.off("objectUpdate", asteriskHandler)
                    emitter.off("applyFilter", doFilterRequest)
                })

                return {
                    // tools
                    emitter, itemRefs, setItemRef, itemRefsReset,
                    // data
                    editorID, fetching, editorLoadedOnce, isEditing, isDirty, fetchFailed, selectedTab,
                    // computed
                    user, objectCache, emailTemplates, objectKey, storeKey, model, validModel, name, objectTypeText,
                    // methods
                    openEditMode, fetchModel, titleClick, handleRecordMenuClicked, editorDone, asteriskHandler
                }
            },
            watch: {
                model() {
                    document.title = 'MandAsoft - ' + this.name
                },
                objectKey(newVal) {
                    if (newVal) {
                        console.log('objectKey changed - reloading...')
                        this.fetching = false
                        this.fetchFailed = false
                        this.editorLoadedOnce = false
                        this.isEditing = {}
                        this.isDirty = {}

                        this.$store.dispatch('objectCache/viewObject', { 'objectKey': this.objectKey }).then(() => {
                            console.log(this.objectKey + ' Viewed');
                        }).catch(error => {
                            console.log(error)
                        })

                        if (!this.model) this.fetchModel()
                    }
                }
            }
        }
    </script>
    <style scoped lang="scss">
    </style>
