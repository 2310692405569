<template>
    <table-row v-if="objectType == 'acquisition'">
        <table-cell @click="detailRequested(obj)">
            <a href="javascript:;">{{date}}</a>
        </table-cell>
        <table-cell>
            {{obj.status}}
        </table-cell>
        <table-cell>
            <a :href="'/company/detailspage/'+obj.seller.id" target="_blank">{{obj.seller.originalName}}</a>
        </table-cell>
        <table-cell>
            {{obj.acquisitionType}}
        </table-cell>
        <table-cell>
            <a :href="'/company/detailspage/'+obj.buyers[0].id" target="_blank">{{obj.buyers[0].originalName}}</a>
        </table-cell>
        <table-cell>
            <a v-if="userResearch" class="margin-right-xsmall" target="_blank" :href="titleURL" @click="openSideLinks(true)" title="Open Detail pages for Acquisition, Seller, and Buyer(s)"><i class="fa fa-external-link"></i></a>
            <a v-if="userResearch" class="margin-right-xsmall" target="_blank" :href="editURL" @click="openSideLinks()" title="Open Edit pages for Acquisition, Seller, and Buyer(s)"><i class="fa fa-edit"></i></a>
        </table-cell>
    </table-row>
    <table-row v-else-if="objectType == 'company'">
        <table-cell @click="detailRequested(obj)">
            <a href="javascript:;">{{obj.name}}</a>
        </table-cell>
        <table-cell>
            {{obj.leadAnalysis}}
        </table-cell>
        <table-cell>
            <record-tags :obj="Object.assign({},obj,{mandaInterest:null})" :hideBasic="true" :isFullInfoContext="false" />
        </table-cell>
        <table-cell>
            {{interest}}
            <record-tags v-if="!interest.length" :obj="Object.assign({},obj,{financials:null,lastFinancial:null})" :hideBasic="true" :isFullInfoContext="false" />
        </table-cell>
    </table-row>
    <table-row v-else-if="objectType == 'person'">
        <table-cell @click="detailRequested(obj)">
            <a href="javascript:;">{{obj.name}}</a>
        </table-cell>
        <table-cell>
            <a v-if="obj.primaryEmail" :href="'mailto://'+obj.primaryEmail.email">{{obj.primaryEmail.email}}</a>
        </table-cell>
        <table-cell>
            <a v-if="obj.primaryTel" :href="'tel://'+obj.primaryTel.tel">{{obj.primaryTel.fullTel}}</a>
        </table-cell>
        <table-cell>
            <record-tags :obj="{objectType:'person',titles:obj.titles}" :isFullInfoContext="false" />
        </table-cell>
    </table-row>
    <table-row v-else>
        <table-cell @click="detailRequested(obj)">
            <a href="javascript:;">{{obj.name}}</a>
        </table-cell>
        <table-cell style="flex:2;">
            {{description}}
        </table-cell>
    </table-row>
</template>

<script>
    import { computed, inject } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { getRecordTools } from '../../composables/RecordTools.js'
    //import { getTextTools } from '../../composables/TextTools.js'
    import TableRow from '../ui/tables/TableRow.vue'
    import TableCell from '../ui/tables/TableCell.vue'
    import RecordTags from './RecordTags.vue'

    export default {
        name: 'RecordImage',
        props: {
            'obj': { type: Object },
            'listIndex': { type: Number }
        },
        components: {
            TableRow, TableCell, RecordTags
        },
        emits: ['detailrequest'],
        setup(props, { emit }) {
            const query = inject('query')
            const { objectType, recordTitle, titleURL, editURL, eventType } = getRecordTools(props)
            const store = useStore()
            const user = computed(() => store.getters['userInfo/currentUser'])
            const userResearch = computed(() => user.value && _.find(user.value.groups, function (g) { return g.id == 9 }));
            const eventIconClass = computed(() => 'icon-' + eventType.value)
            const date = computed(() => {
                return props.obj && props.obj.date ? (new Date(props.obj.date)).toLocaleDateString() : '';
            })
            const interest = computed(() => {
                if (!props.obj || !props.obj.mandaInterest) return 'Unknown';

                // record-tags will render it instead if there's a timing
                if (props.obj.mandaInterest.timingID > 0 && props.obj.mandaInterest.timing != 'Unknown') return '';

                let mandaInterestTags = [];
                
                if (props.obj.mandaInterest.sellInterest) mandaInterestTags.push('Sell');
                if (props.obj.mandaInterest.buyInterest) mandaInterestTags.push('Buy');
                if (props.obj.mandaInterest.divestInterest) mandaInterestTags.push('Divest');
                if (props.obj.mandaInterest.valueInterest) mandaInterestTags.push('Valuation');
                if (props.obj.mandaInterest.financeInterest) mandaInterestTags.push('Financing');
                if (props.obj.mandaInterest.noInterest) mandaInterestTags.push('None');

                if (mandaInterestTags.length > 0) {
                    return mandaInterestTags.join(', ');
                } else {
                    return 'Unknown';
                }
            })
            const description = computed(() => {
                if (!props.obj || !props.obj.description) return '';

                // descriptions have html tags in them sometimes which we don't know how to render, and don't want to deal with truncating closing tags
                // just strip the html tags and keep the text
                let div = document.createElement('div');
                div.innerHTML = props.obj.description;
                let parsedDescription = div.textContent || div.innerText || props.obj.description;

                if (parsedDescription.length > 255) return parsedDescription.substring(0, 255) + '...';
                else return parsedDescription;
            })

            const openSideLinks = (details) => {
                if (props.obj && objectType.value == 'acquisition') {
                    if (props.obj.seller) {
                        window.open("/company/" + (details ? 'detailspage' : 'edit') + "/" + props.obj.seller.id, "_blank");
                    }
                    if (props.obj.buyers) {
                        _.each(props.obj.buyers, function (b) {
                            window.open("/company/" + (details ? 'detailspage' : 'edit') + "/" + b.id, "_blank");
                        });
                    }
                }
            }
            const detailRequested = (object) => {
                emit('detailrequest', {
                    'obj': object,
                    'listIndex': props.listIndex,
                    'cardID': 0,
                    'queryID': query && query.value ? query.value.id : 0
                })
            }

            return {
                objectType, recordTitle, eventType, eventIconClass,
                date, titleURL, editURL, interest, description,
                openSideLinks, detailRequested, userResearch
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>